<template>
  <v-container fluid>
    <register-digi-board
      v-if="showRegisterDialog"
      :digiboardid="digiBoardExternalId"
      @success="fetchDigiBoardDetails"
      @closed="showRegisterDialog = false"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-television-guide
      </v-icon> Device Status
    </h2>
    <br>
    <v-btn
      color="red darken-3"
      @click="$router.push({ name: 'Digi-Boards' })"
    >
      Back
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
        >
          <v-card
            class="my-card-style"
          >
            <v-toolbar
              color="#37474F"
              dark
            >
              <v-toolbar-title class="my-toolbar-style">
                Device
              </v-toolbar-title>
            </v-toolbar>
            <v-col
              cols="12"
              lg="12"
              class="text-right"
            >
              <div v-if="permissionCheck('board-registration')">
                <v-btn
                  v-if="showRegisterButton"
                  color="#FF3700"
                  @click="registerDigiBoard()"
                >
                  Register
                </v-btn>
              </div>
              <div v-if="permissionCheck('re-register-licence')">
                <v-btn
                  v-if="isLicenceRegistered"
                  color="#FF3700"
                  @click="reRegisterDigiBoard()"
                >
                  Re-Register
                </v-btn>
              </div>
            </v-col>
            <v-container
              v-if="showDigiBoardDetails"
              fluid
            >
              <p class="my-heading-style">
                Device Details
              </p>

              <p class="my-p-style">
                Device Number:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.board_number }}
                  <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <p class="my-p-style">
                Device External Id:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.external_id }} <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <p class="my-p-style">
                Device Locaton:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.location }} <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </font>
              </p>
              <hr>
              <br>
              <p class="my-heading-style">
                Licence Details
              </p>
              <p
                v-if="digiBoardDetails.licence !== null"
                class="my-p-style"
              >
                Starts From:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.licence.start | convertStringToLocalDatetime }}
                </font>
              </p>
              <p
                v-else
                class="my-p-style"
              >
                Starts From:-
                <font
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p
                v-if="digiBoardDetails.licence !== null && digiBoardDetails.licence.registered_at !== null"
                class="my-p-style"
              >
                Registered At:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.licence.registered_at | convertStringToLocalDatetime }}
                </font>
              </p>
              <p
                v-else
                class="my-p-style"
              >
                Registered At:-
                <font
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p
                v-if="digiBoardDetails.licence !== null"
                class="my-p-style"
              >
                Expires At:-
                <font
                  class="font-my-style"
                >
                  {{ digiBoardDetails.licence.end | convertStringToLocalDatetime }}
                </font>
              </p>
              <p
                v-else
                class="my-p-style"
              >
                Expires At:-
                <font
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p
                v-if="digiBoardDetails.licence !== null"
                class="my-p-style"
              >
                Licence Status:-
                <font
                  :color="getColor(digiBoardDetails.licence.status)"
                  class="my-licence-status"
                >
                  {{ digiBoardDetails.licence.status | statusUpdate }}
                </font>
              </p>
              <p
                v-else
                class="my-p-style"
              >
                Licence Status:-
                <font
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
            </v-container>
          </v-card>
          <v-alert
            v-if="!permissionCheck('read-board')"
            text
            prominent
            type="warning"
            color="red"
            border="left"
            class="authorization-alert"
          >
            You are not authorized to perform this action. Please contact your administrator.
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-card
            class="my-card-style"
          >
            <v-card
              dark
              flat
            >
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  Device Activity
                </v-toolbar-title>
              </v-toolbar>
              <v-img
                :src="image"
                gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
              >
                <v-container class="fill-height">
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <div class="my-date-style">
                      {{ currentDateTime }}
                    </div>
                  </v-row>
                </v-container>
              </v-img>
            </v-card>
          </v-card>
          <v-card-text class="py-0">
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="(item, index) in latestThreeBoardActivities"
                :key="index"
                color="#FF3700"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <p
                      class="my-p-style"
                    >
                      <font
                        class="font-my-style"
                      >
                        {{ item.event_triggered_at | convertStringToLocalDatetime }}
                      </font>
                    </p>
                  </v-col>
                  <v-col>
                    <strong>Device Activity {{ index + 1 }}</strong>
                    <br>
                    <br>
                    <div>
                      <p
                        class="my-p-style"
                      >
                        Published At:-
                        <font
                          class="font-my-style"
                        >
                          {{ item.event_triggered_at | convertStringToLocalDatetime }}
                        </font>
                      </p>
                    </div>
                    <div>
                      <p
                        v-if="item.board_updated_at !== null"
                        class="my-p-style"
                      >
                        Device Updated At:-
                        <font
                          class="font-my-style"
                        >
                          {{ item.board_updated_at | convertStringToLocalDatetime }}
                        </font>
                      </p>
                      <p
                        v-else
                        class="my-p-style"
                      >
                        Device Updated At:-
                        <font
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                    </div>
                    <div>
                      <p
                        class="my-p-style"
                      >
                        Device Status:-
                        <font
                          :color="getBoardStatusColor(item.status)"
                          class="my-licence-status"
                        >
                          {{ item.status | boardStatusUpdate }}
                        </font>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <v-alert
              v-if="!permissionCheck('read-board-update-records-list')"
              text
              prominent
              type="warning"
              color="red"
              border="left"
              class="authorization-alert"
            >
              You are not authorized to perform this action. Please contact your administrator.
            </v-alert>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <re-register-alert-dialog
      v-if="showReRegisterAlertDialog"
      title="Do you want to proceed ?"
      message="This action will unlink your licence and remove the connection from the device it's been registered on."
      confirm-button="Yes"
      cancel-button="No"
      @closed="showReRegisterAlertDialog = false"
      @confirmed="openReRegisterConfirmForm()"
    />
    <re-register-digiboard-licence
      v-if="showReRegisterDialog"
      :digiboardid="digiBoardExternalId"
      @success="fetchDigiBoardDetails"
      @closed="showReRegisterDialog = false"
    />
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import moment from 'moment';
import { cdnUrl } from 'src/app-globals';
import RegisterDigiBoard from './RegisterDigiBoard.vue';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import global from 'src/mixins/global';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog.vue';
import ReRegisterDigiBoardLicence from './ReRegisterDigiBoardLicence.vue';

export default {
  name: 'DigiBoardDetails',
  components: {
    RegisterDigiBoard,
    'centre-spinner': spinner,
    're-register-alert-dialog': DeleteDialog,
    're-register-digiboard-licence': ReRegisterDigiBoardLicence,
  },
  filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    statusUpdate (status) {
      if (status === 'active') {
        return 'Active*';
      } else if (status === 'trial') {
        return 'Trial';
      } else { return 'Expired'; }
    },
    boardStatusUpdate (status) {
      if (status === 'published') {
        return 'Published';
      } else if (status === 'completed') {
        return 'Completed*';
      } else { return 'Pending'; }
    },
  },
  mixins: [global],
  data () {
    return {
      image: `${cdnUrl}/website/ActivityImage.jpg`,
      showRegisterDialog: false,
      showRegisterButton: false,
      loading: false,
      digiBoardExternalId: '',
      currentDateTime: null,
      isLicenceRegistered: false,
      showReRegisterAlertDialog: false,
      showReRegisterDialog: false,
    };
  },
  computed: {
    digiBoardDetails () {
      return this.$store.getters['digiboards/getBoardDetails'];
    },
    latestThreeBoardActivities () {
      return this.$store.getters['digiboards/getLatestThreeBoardActvities'];
    },
    boardActivityLastUpdatedAt () {
      return this.$store.getters['digiboards/getActivityLastUpdatedAt'];
    },
    showDigiBoardDetails () {
      return Object.keys(this.digiBoardDetails).length > 0;
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.permissionCheck('read-board') === false && vm.permissionCheck('read-board-update-records-list') === false) {
        vm.$router.push({ name: 'Dashboard' });
      } else {
        next();
      }
    });
  },
  watch: {
    boardActivityLastUpdatedAt: function (val) {
      this.permissionCheck('read-board') && this.fetchDigiBoardDetails();
      this.permissionCheck('read-board-update-records-list') && this.fetchDigiBoardActivities();
    },
  },
  created () {
    this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
    setInterval(() => this.liveDateTime());
  },
  async mounted () {
    this.loading = true;
    this.permissionCheck('read-board') && await this.fetchDigiBoardDetails();
    this.permissionCheck('read-board-update-records-list') && await this.fetchDigiBoardActivities();
    this.loading = false;
  },
  methods: {
    async fetchDigiBoardDetails () {
      const deviceExternalId = this.$route.params.external_id;
      await this.$store.dispatch('digiboards/fetchBoardDetails', {
        digiBoardId: deviceExternalId,
      });
      if (this.digiBoardDetails.licence !== null && this.digiBoardDetails.licence.registered_at === null && (this.digiBoardDetails.licence.status === 'active' || this.digiBoardDetails.licence.status === 'trial')) {
        this.showRegisterButton = true;
      } else {
        this.showRegisterButton = false;
      }
      if (this.digiBoardDetails.licence !== null && this.digiBoardDetails.licence.registered_at !== null && (this.digiBoardDetails.licence.status === 'active' || this.digiBoardDetails.licence.status === 'trial')) {
        this.isLicenceRegistered = true;
      } else {
       this.isLicenceRegistered = false;
      }
    },
    async fetchDigiBoardActivities () {
      const deviceExternalId = this.$route.params.external_id;
      await this.$store.dispatch('digiboards/fetchLatestThreeBoardActvities', {
        params: {
          limit: 3,
          digiBoardId: deviceExternalId,
          orderBy: 'updated_at',
        },
      });
    },
    registerDigiBoard () {
      const deviceExternalId = this.$route.params.external_id;
      this.digiBoardExternalId = deviceExternalId;
      this.showRegisterDialog = true;
    },
    getColor (status) {
      if (status === 'active') {
        return '#2E7D32';
      } else if (status === 'trial') {
        return '#EF6C00';
      } else { return '#C62828'; }
    },
    getBoardStatusColor (status) {
      if (status === 'published') {
        return '#E65100';
      } else if (status === 'completed') {
        return '#2E7D32';
      } else {
        return '#C62828';
      }
    },
    liveDateTime () {
      this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
    },
    reRegisterDigiBoard () {
      this.showReRegisterAlertDialog = true;
    },
    openReRegisterConfirmForm () {
      const deviceExternalId = this.$route.params.external_id;
      this.digiBoardExternalId = deviceExternalId;
      this.showReRegisterAlertDialog = false;
      this.showReRegisterDialog = true;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
}
.my-card-style {
    border-radius: 15px;
    background-color: #ECEFF1;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-licence-status {
    font-size: 15px;
    font-weight: bold;
}
.my-heading-style {
    font-size: 17px;
    font-weight: bold;
    color: #263238;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-toolbar-style {
    font-size: 20px;
    font-weight:500;
}
.my-date-style {
  font-size: 25px;
  font-weight: bold;
}
.no-permission-message {
  color: red;
  margin-left: 5px;
  margin-right:5px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
