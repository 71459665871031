<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-monitor-lock
        </v-icon> Register Device
      </h2>
      <br>
      <p
        v-if="activationSuccess"
        class="success-message-style"
      >
        <font>
          <v-icon
            size="60"
            color="#2E7D32"
          >
            mdi-checkbox-marked-circle
          </v-icon>
          {{ activationSuccessMessage }}
        </font>
      </p>
      <p
        v-if="activationFailed"
        class="fail-message-style"
      >
        <font>
          <v-icon
            size="60"
            color="#C62828"
          >
            mdi-close-thick
          </v-icon>
          {{ activationFailedMessage }}
        </font>
      </p>
      <div v-if="!activationSuccess">
        <v-form>
          <v-card-text class="text-body-1 text-center">
            <v-container fluid>
              <p
                v-if="!showActivationProgress"
                class="my-p-style"
              >
                <font>
                  Please enter the 6 digit licence activation key here
                </font>
              </p>
              <v-container v-if="showActivationProgress">
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col
                    class="activation-alert"
                    cols="12"
                  >
                    {{ activationProcessAlert }}
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear
                      color="#FF3700"
                      indeterminate
                      rounded
                      height="10"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <br>
              <div
                class="otp-style"
                style="max-width: 300px"
              >
                <v-otp-input
                  v-model="licenceKey"
                  length="6"
                  :disabled="showActivationProgress"
                />
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="!showActivationProgress">
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              :disabled="!isLicenceKeyAvailable"
              @click="activateDevice()"
            >
              Activate
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'RegisterDigiBoard',
  props: {
    digiboardid: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      licenceKey: '',
      activationProcessAlert: '',
      showActivationProgress: false,
      activationSuccess: false,
      activationFailed: false,
      activationSuccessMessage: '',
      activationFailedMessage: '',
    };
  },
  computed: {
    isLicenceKeyAvailable () {
      if (this.licenceKey.length === 6) {
        return true;
      }
      return false;
    },
    deviceRegistrationSuccessData () {
      return this.$store.getters['digiboards/getDeviceRegistrationSuccessData'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
        this.licenceKey = '';
        this.$store.dispatch('digiboards/clearDeviceRegistrationSuccessData');
      }
    },
    deviceRegistrationSuccessData (val) {
      if (val.includes(this.digiboardid)) {
        this.showActivationProgress = false;
        this.activationFailed = false;
        this.activationSuccess = true;
        this.activationSuccessMessage = 'Device registration completed successfully.';
        this.licenceKey = '';
        setTimeout(() => {
          this.showModal = false;
          this.$emit('success');
        }, 5000);
      }
    },
  },
  methods: {
    activateDevice () {
      this.activationFailed = false;
      this.activationSuccess = false;
      this.showActivationProgress = true;
      this.activationProcessAlert = 'Checking...';
      this.$store.dispatch('digiboards/activateDevice', {
        digiBoardId: this.digiboardid,
        licenceKey: this.licenceKey,
      }).then(response => {
        this.activationProcessAlert = 'Registration processing...';
      }).catch(error => {
        this.showActivationProgress = false;
        this.activationFailed = true;
        this.activationFailedMessage = error.response.data.message;
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.otp-style {
  margin: auto;
}
.success-message-style {
  font-size: 18px;
  font-weight: bold;
  color: #2E7D32;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.fail-message-style {
  font-size: 18px;
  font-weight: bold;
  color: #C62828;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.activation-alert {
  font-size: 20px;
  color: #37474F;
  font-weight: bold;
  text-align: center;
}
</style>
