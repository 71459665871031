<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-television-off
        </v-icon> Unlink Licence
      </h2>
      <form>
        <v-container>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="10"
            >
              <v-card-text class="text-body-1 text-center">
                <p class="my-p-style">
                  Please provide a reason for disconnecting your current licence from this device <v-icon color="#2E7D32">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </p>
                <v-container v-if="showUnlinkProcess">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col
                      class="unlink-alert"
                      cols="12"
                    >
                      {{ unlinkProcessAlerts }}
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear
                        color="#FF3700"
                        indeterminate
                        rounded
                        height="10"
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-text-field
                  v-model="reasonForReRegister"
                  outlined
                  label="Enter a Reason*"
                  hint="For Eg: Device removed"
                  persistent-hint
                  dense
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              :disabled="!isReasonEntered"
              @click="startCancelDigiBoardLicenceProcess()"
            >
              Unlink
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'ReRegisterDigiBoardLicence',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      digiboardid: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        reasonForReRegister: '',
        loading: false,
        showUnlinkProcess: false,
        unlinkProcessAlerts: '',
      };
    },
    computed: {
      isReasonEntered () {
        if (this.reasonForReRegister !== '') {
          return true;
        }
        return false;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      startCancelDigiBoardLicenceProcess () {
        this.unlinkProcessAlerts = 'Checking...';
        this.showUnlinkProcess = true;
        setTimeout(() => {
          this.cancelDigiBoardLicence();
        }, 2000);
      },
      async cancelDigiBoardLicence () {
        this.unlinkProcessAlerts = 'Unlinking...';
        await this.$store.dispatch('digiboards/cancelDigiBoardLicence', {
          digiBoardId: this.digiboardid,
          comment: {
            comment: this.reasonForReRegister,
          },
        }).then(response => {
          setTimeout(() => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Device connection removed.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.$emit('success');
            this.unlinkProcessAlerts = '';
            this.showUnlinkProcess = false;
            this.showModal = false;
          }, 5000);
        }).catch(() => {
          this.unlinkProcessAlerts = '';
          this.showUnlinkProcess = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
  margin-bottom: 30px;
}
.unlink-alert {
  font-size: 22px;
  color: #37474F;
  font-weight: bold;
  text-align: center;
}
</style>
